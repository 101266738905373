// Generated by Framer (91d32d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {zufKRbnyM: {hover: true}};

const cycleOrder = ["zufKRbnyM"];

const serializationHash = "framer-q0DtM"

const variantClassNames = {zufKRbnyM: "framer-v-1hhp54c"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, link, width, ...props}) => { return {...props, gAVOjRr3t: link ?? props.gAVOjRr3t, hI716oahX: image ?? props.hI716oahX ?? {src: "https://framerusercontent.com/images/gtzDysUq73PcIFESNRa0djSYuTY.svg"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string};link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, hI716oahX, gAVOjRr3t, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "zufKRbnyM", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Link href={gAVOjRr3t}><motion.a {...restProps} animate={variants} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1hhp54c", className, classNames)} framer-loy6ip`} data-border data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"zufKRbnyM"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-a519f4cd-3101-490c-9ed0-78558c5016c5, rgb(35, 35, 41)) /* {\"name\":\"Dark 15\"} */", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-aa4ece83-4ae0-4526-8913-b8d80992cb77, rgb(28, 28, 33))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} {...addPropertyOverrides({"zufKRbnyM-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 24, intrinsicWidth: 24, pixelHeight: 24, pixelWidth: 24, sizes: "24px", ...toResponsiveImage(hI716oahX)}} className={"framer-108a1ke"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"gyZlImcUi"}/></motion.a></Link></Transition>
</LayoutGroup>)

});

const css = [".framer-q0DtM[data-border=\"true\"]::after, .framer-q0DtM [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-q0DtM.framer-loy6ip, .framer-q0DtM .framer-loy6ip { display: block; }", ".framer-q0DtM.framer-1hhp54c { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: auto; justify-content: center; overflow: hidden; padding: 10px 10px 10px 10px; position: relative; text-decoration: none; width: auto; will-change: var(--framer-will-change-override, transform); }", ".framer-q0DtM .framer-108a1ke { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 24px); overflow: hidden; position: relative; width: 24px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-q0DtM.framer-1hhp54c { gap: 0px; } .framer-q0DtM.framer-1hhp54c > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-q0DtM.framer-1hhp54c > :first-child { margin-left: 0px; } .framer-q0DtM.framer-1hhp54c > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 44
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"q1CYPa1F4":{"layout":["auto","auto"]}}}
 * @framerVariables {"hI716oahX":"image","gAVOjRr3t":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramertCzQXmoWi: React.ComponentType<Props> = withCSS(Component, css, "framer-q0DtM") as typeof Component;
export default FramertCzQXmoWi;

FramertCzQXmoWi.displayName = "Icon Button";

FramertCzQXmoWi.defaultProps = {height: 44, width: 44};

addPropertyControls(FramertCzQXmoWi, {hI716oahX: {__defaultAssetReference: "data:framer/asset-reference,gtzDysUq73PcIFESNRa0djSYuTY.svg?originalFilename=x.svg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, gAVOjRr3t: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramertCzQXmoWi, [])